import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useClaimList() {
  // Use toast
  const toast = useToast()

  const refClaimListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { label: 'Verzekernemer', key: 'policyHolderRelation', sortable: true },
    { label: 'Product', key: 'product', sortable: true },
    { label: 'Notities', key: 'claimNote', sortable: true },
    { label: 'Overleden op', key: 'claimDateOfDeath', sortable: true },
    { label: 'Aangemaakt op', key: 'timestamp', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalClaims = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const showOverlay = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refClaimListTable.value ? refClaimListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalClaims.value,
    }
  })

  const refetchClaimData = () => {
    refClaimListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchClaimData()
  })

  const fetchClaim = (ctx, callback) => {
    showOverlay.value = true
    store
      .dispatch('app-claim/fetchClaims', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortDesc: isSortDirDesc.value,
        sortBy: sortBy.value,
        status: '110,111,112,113,114',
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        showOverlay.value = false
        totalClaims.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error bij het ophalen van de claim',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const approveStatusOptions = [
    { label: 'Goedgekeurd', value: 111 },
    { label: 'Goedgekeurd onder voorwaarde', value: 112 },
    { label: 'Gedeeltelijk goedgekeurd', value: 113 },
  ]

  return {
    fetchClaim,
    tableColumns,
    perPage,
    currentPage,
    totalClaims,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refClaimListTable,
    refetchClaimData,
    showOverlay,
    approveStatusOptions,
  }
}
