import axios from '@axios'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchClaims(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}orders`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateClaim(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${axiosDefaultConfig.backendEndPoint}orders/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMenuStatistics(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}statistics`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
